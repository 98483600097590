
import { setStroe, getStore } from '@/lib/utils.js'
const side = {
  state: {
    installFlag: getStore({
      name: 'installFlag'
    }),
  },
  mutations: {
    setInstallFlag(state, flag) {
      state.installFlag = flag
      setStroe({
        name: "installFlag",
        content: flag,
      })
    },
  },
}
export default side