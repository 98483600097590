import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import '@/static/index.css'
import store from './store'
import VuePwaInstallPlugin from "vue-pwa-install";
Vue.use(VuePwaInstallPlugin);
import Loading from '@/components/loading.vue'
import 'vant/lib/rate/style';
import { Rate } from 'vant';

Vue.use(Rate);
Vue.config.productionTip = false
import { VuePwaInstallMixin } from "vue-pwa-install";
Vue.mixin(VuePwaInstallMixin);
Vue.component('oLoading', Loading);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
