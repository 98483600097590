<template>
  <div>
    <template v-if="showLoading">
      <div class="loading-box" :class="{ mask: mask }">
        <div class="loading-wrap">
          <div class="loading-css">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
            <div></div>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  name: "oLoading",
  props: {
    mask: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    showLoading(val) {
      this.$emit("change", val);
    },
  },
  data() {
    return {
      showLoading: false,
    };
  },
  methods: {
    show() {
      this.showLoading = true;
    },
    hide() {
      this.showLoading = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.loading-box {
  position: fixed;
  left: 50%;
  top: 50%;
  z-index: 19;
  transform: translate(-50%, -50%);
  z-index: 3000;
  &.mask {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }
  .loading-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 50%;
    top: 50%;
    z-index: 19;
    transform: translate(-50%, -50%);
    width: 120px;
    height: 120px;
    background: rgba(0, 0, 0, 0.6);
    box-shadow: 0 0 .1rem 1px rgba($color: #eadada, $alpha: 0.2);
    border-radius: 0.3rem;
  }
  .loading-css {
    position: relative;
    box-sizing: border-box;
    display: block;
    font-size: 0;
    color: #30f89c;
    width: 60px;
    height: 60px;
  }
  .loading-css,
  .loading-css > div {
    position: relative;
    box-sizing: border-box;
  }

  .loading-css {
    display: block;
    font-size: 0;
    color: #30f89c;
  }

  .loading-css.la-dark {
    color: #333;
  }

  .loading-css > div {
    display: inline-block;
    float: none;
    background-color: currentColor;
    border: 0 solid currentColor;
  }

  .loading-css {
    width: 60px;
    height: 60px;
  }

  .loading-css > div {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 8px;
    height: 8px;
    margin-top: -4px;
    margin-left: -4px;
    border-radius: 100%;
    animation: ball-spin-clockwise 1s infinite ease-in-out;
  }

  .loading-css > div:nth-child(1) {
    top: 5%;
    left: 50%;
    animation-delay: -0.875s;
  }

  .loading-css > div:nth-child(2) {
    top: 18.1801948466%;
    left: 81.8198051534%;
    animation-delay: -0.75s;
  }

  .loading-css > div:nth-child(3) {
    top: 50%;
    left: 95%;
    animation-delay: -0.625s;
  }

  .loading-css > div:nth-child(4) {
    top: 81.8198051534%;
    left: 81.8198051534%;
    animation-delay: -0.5s;
  }

  .loading-css > div:nth-child(5) {
    top: 94.9999999966%;
    left: 50.0000000005%;
    animation-delay: -0.375s;
  }

  .loading-css > div:nth-child(6) {
    top: 81.8198046966%;
    left: 18.1801949248%;
    animation-delay: -0.25s;
  }

  .loading-css > div:nth-child(7) {
    top: 49.9999750815%;
    left: 5.0000051215%;
    animation-delay: -0.125s;
  }

  .loading-css > div:nth-child(8) {
    top: 18.179464974%;
    left: 18.1803700518%;
    animation-delay: 0s;
  }

  .loading-css.la-sm {
    width: 22px;
    height: 22px;
  }

  .loading-css.la-sm > div {
    width: 4px;
    height: 4px;
    margin-top: -2px;
    margin-left: -2px;
  }

  .loading-css.la-2x {
    width: 64px;
    height: 64px;
  }

  .loading-css.la-2x > div {
    width: 16px;
    height: 16px;
    margin-top: -8px;
    margin-left: -8px;
  }

  .loading-css.la-3x {
    width: 96px;
    height: 96px;
  }

  .loading-css.la-3x > div {
    width: 24px;
    height: 24px;
    margin-top: -12px;
    margin-left: -12px;
  }

  @keyframes ball-spin-clockwise {
    0%,
    100% {
      opacity: 1;
      transform: scale(1);
    }

    20% {
      opacity: 1;
    }

    80% {
      opacity: 0;
      transform: scale(0);
    }
  }
}
@keyframes ball-circus-position {
  50% {
    left: 100%;
  }
}

@keyframes ball-circus-size {
  50% {
    transform: scale(0.3, 0.3);
  }
}
</style>
